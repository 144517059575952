import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getStatistics} from "../../../../redux/actions/actions";

const Statistics = () => {
  const dispatch = useDispatch();

  const { statistics } = useSelector((state) => state.statistics);
  const { location } = useSelector((state) => state.location);

  useEffect(() => {
    dispatch(getStatistics());
  }, [])

  return statistics?.length !== 0 && (
    <div className="absolute w-full bg-white xl:relative bottom-0 z-30 status py-8 xl:py-5 xl:mt-10">
      <div className="container mx-auto rounded-lg">
        <div className="box flex justify-between items-center p-2 rounded-lg md:flex-wrap md:gap-5">
          {statistics &&
            statistics?.map((item) => (
              <div key={item.id} className="flex flex-col gap-2 md:w-[45%]">
                <div className="flex flex-col gap-2 md:w-full text-center">
                  <h1 className="text-4xl font-bold xs:text-2xl text-center text-logo">
                    {item.value}
                  </h1>
                  <span className="text-2xl xs:text-xl">
                      {location === "uz" ? item.name_uz : item.name_ru}
                    </span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Statistics;