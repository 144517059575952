import Marquee from "react-fast-marquee";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCostumers} from "../../../../redux/actions/actions";
import {useTranslation} from "react-i18next";
const Costumers = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const { costumers } = useSelector(state => state.costumers)

  useEffect(() => {
    dispatch(getCostumers())
  }, [])

  return costumers.length !== 0 && (
    <section className="partners py-20">
      <div className="w-full">
        <div className="box">
          <h2 className='text-4xl font-bold text-logo mb-12 text-center'>{t("costumers")}</h2>
          
          <Marquee pauseOnHover={true}>
            <div className="flex items-center gap-20 md:gap-8 xs:gap-5">
              {costumers &&
                costumers?.map((item) => (
                  <img
                    key={item.id}
                    className="w-full h-[100px] object-contain md:ml-9 img"
                    src={item.icon}
                    alt=""
                  />
                ))}
            </div>
          </Marquee>
          <Marquee
            pauseOnHover={true}
            direction={"right"}
            className="mt-10 md:gap-8 xs:gap-5"
          >
            <div className="flex items-center gap-20 md:gap-8 xs:gap-5">
              {costumers &&
                costumers
                  ?.reverse()
                  .map((item) => (
                    <img
                      key={item.id}
                      className="w-full h-[100px] object-contain md:ml-9 img_reverse"
                      src={item.icon}
                      alt=""
                    />
                  ))}
            </div>
          </Marquee>
        </div>
      </div>
    </section>
  )
}

export default Costumers