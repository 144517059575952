import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./../Home/components/news/index.css";
import CommonSection from "../../components/common-section/CommonSection";
import { getNews } from "../../redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Pagination from "../../components/Pagination/Pagination";
import Image from "../../assets/no-results-bg.2d2c6ee3.png";

export default function NewsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { location } = useSelector((state) => state.location);
  const { news } = useSelector((state) => state.news);

  useEffect(() => {
    dispatch(getNews());
  }, []);

  const handlePageChange = (page) => {
    dispatch(getNews({ page }));
  };

  return (
    <>
      <CommonSection title={t("news")} breadCrumb={t("news")} />
      {news?.result?.length === 0 || news?.length === 0 ? (
        <div className={"mx-auto w-full text-center pb-10"}>
          <img src={Image} alt="" className={"w-1/4 mx-auto"} />
          <h1 className={"text-3xl"}>{t("soon")}</h1>
        </div>
      ) : (
        <section id="news" className="pt-20 mb-20">
          <div className="container">
            <h2 className="text-4xl font-bold text-logo mb-16 text-center">
              {t("news")}
            </h2>
            <div className="flex flex-wrap justify-start gap-[3%] lg:justify-evenly mb-10">
              {news &&
                news?.result?.map((e, i) => (
                  <Link
                    key={i}
                    to={`/news/${e.id}`}
                    className="w-[31%] lg:w-5/12 news-card shadow-2xl mb-10 relative mr-1 overflow-hidden"
                  >
                    <div className="relative">
                      {e?.files[0]?.is_video ? (
                        <iframe
                          src={`${e?.files[0]?.video_url}`}
                          width={"100%"}
                          height={"100%"}
                          title="content video"
                          style={{
                            height: "230px",
                            width: "100%",
                            objectFit: "cover",
                            backgroundColor: "#ccc",
                          }}
                          frameBorder={0}
                          allowFullScreen={true}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                      ) : (
                        <img
                          className="h-80 object-cover bg-bd"
                          width={"1024"}
                          height={"768"}
                          src={`${e?.files[0]?.file}`}
                          alt="img"
                        />
                      )}
                      <div className="absolute top-3 left-3 bg-hover text-white font-bold uppercase rounded-lg p-1 px-2">
                        {moment(e.date).format("DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                    <div className="p-8">
                      <h3 className="text-xl hover:text-hover font-semibold transition-colors mb-4">
                        {`${location === "uz"
                            ? e.title_uz.substring(0, 75)
                            : e.title_ru.substring(0, 75)
                          }...`}
                      </h3>
                      {/* <p>{e.text}</p> */}
                      <div
                        // className="text-logo-secondary hover:text-hover text-xs"
                        dangerouslySetInnerHTML={{
                          __html:
                            location === "uz"
                              ? e?.content_uz?.substring(3, 210)
                              : e?.content_ru?.substring(3, 210),
                        }}
                      />
                    </div>
                  </Link>
                ))}
            </div>
            <div className={'flex justify-center pb-3'}>
              <Pagination
                totalItems={news?.count}
                itemsPerPage={12}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </section>
      )}

    </>
  );
}
