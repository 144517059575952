import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProtcesSertification } from "../../../../redux/actions/actions";
import "./index.css";

const ProtcesSertification = () => {
  const dispatch = useDispatch();

  const { protcesSertification } = useSelector(
    (state) => state.protcesSertification
  );
  const { location } = useSelector((state) => state.location);

  const [active, setActive] = useState("e75b9a73-1702-4e40-a98f-fb218154e0c9");

  useEffect(() => {
    dispatch(getProtcesSertification());
  }, []);

  const clickHandler = (id) => {
    setActive(id);
  };
  const changeHandler = (el) => {
    protcesSertification.map(e => {
      if((e.title_uz || e.title_ru) == el) setActive(e.id)
    })
  }

  return (
    protcesSertification.length !== 0 && (
      <section className="protcess-sertification">
        <div className="container">
          <h2 className="text-4xl font-bold text-logo mb-20 text-center">
            {location === "uz"
              ? "Sertifikatsiyalash jarayoni"
              : "Процедура проведения сертификации"}
          </h2>
          <div className="protcess-sertification__wrapper md:flex-col">
            <ul className="protcess-sertification__list md:hidden">
              {protcesSertification &&
                protcesSertification.map((e) => (
                  <li
                    key={e.id}
                    className={`protcess-sertification__item ${
                      e.id === active && "protcess-sertification__item--active"
                    }`}
                    onClick={() => clickHandler(e.id)}
                  >
                    <h3
                      className="protcess-sertification__item-title"
                      dangerouslySetInnerHTML={{
                        __html:
                          location === "uz"
                            ? e?.title_uz.substring(0, 75)
                            : e?.title_ru.substring(0, 75),
                      }}
                    />
                  </li>
                ))}
            </ul>
            {protcesSertification &&
              protcesSertification.map(
                (e) =>
                  e.id === active && (
                    <>
                      <select onChange={(e) => changeHandler(e.target.value)} className="hidden md:block z-10 mb-10 border border-bd border-solid rounded-sm p-2">
                        {protcesSertification &&
                          protcesSertification.map((e) => 
                            <option 
                              key={e.id}
                              dangerouslySetInnerHTML={{
                                __html:
                                  location === "uz"
                                  ? e?.title_uz.substring(0, 75)
                                  : e?.title_ru.substring(0, 75),
                            }}
                            />
                          )}
                      </select>
                      <div
                        key={e.id}
                        className="protcess-sertification__content md:min-w-full"
                      >
                        {/* <h3 className="protcess-sertification__title"
                          dangerouslySetInnerHTML={{
                            __html:
                              location === "uz"
                                ? e?.title_uz
                                : e?.title_ru,
                          }}
                        /> */}
                        <p
                          className="protcess-sertification__text"
                          dangerouslySetInnerHTML={{
                            __html: location === "uz" ? e?.text_uz : e?.text_ru,
                          }}
                        />
                      </div>
                    </>
                  )
              )}
          </div>
        </div>
      </section>
    )
  );
};

export default ProtcesSertification;
