import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { switchLang } from "../../redux/actions/actions";
import MenuItems from "./MenuItems";
import logo from "../../assets/logo.svg";
import { useTranslation } from "react-i18next";
import "../../index.css";
import "./index.css";
import Rek15 from "../../assets/rek15.png";
import Rek25 from "../../assets/rek25.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import VanillaTilt from "vanilla-tilt";


function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

const Header = () => {
  const menu_items = [
    {
      name: "Biz haqimizda",
      ru: "О нас",
      uz: "Biz haqimizda",
      id: 13,
      path: "/about-us",
      children: [
        {
          name: "TTV sertifikatlashtirish idorasi",
          ru: "Орган по сертификации ТСТ",
          uz: "TTV sertifikatlashtirish idorasi",
          id: 1,
          path: "/sertifikatsiya-organi",
        },
        {
          name: "TTV sertifikatlashtirish sinov markazi",
          ru: "Сертификационный испытательный центр ТСТ",
          uz: "TTV sertifikatlashtirish sinov markazi",
          path: "/sertifikatsiya-sinov-markazi",
          id: 2,
        },
        {
          name: "TQTT texnik vositalarini sertifikatlashtirish maxsus idorasi",
          ru: "Специальный орган по сертификации технических средств СОРМ",
          uz: "TQTT texnik vositalarini sertifikatlashtirish maxsus idorasi",
          path: "/sertifikatsiya-maxsus-sinov-markazi",
          id: 3,
        },
        // {
        //   name: "TQTT texnik vositalari maxsus sinov laboratoriyasi",
        //   ru: "Специальная испытательная лаборатория технических средств СОРМ",
        //   uz: "TQTT texnik vositalari maxsus sinov laboratoriyasi",
        //   path: "/sertifikatsiya-maxsus-organi",
        //   id: 4,
        // },
        {
          name: "Ko'p beriladigan savollar",
          ru: "Часто задаваемые вопросы",
          uz: "Ko'p beriladigan savollar",
          path: "/most-questions",
          id: 5,
        },
      ],
    },
    {
      name: "Xizmatlar",
      // path: "/services",
      id: 22,
      ru: "Услуги",
      uz: "Xizmatlar",
      href: "https://xizmatlar.unicon.uz/",
      // title_uz:
      //   "Tez orada shartnomani masofaviy rasmiylashtirish va xizmatdan foydalanish imkoniyati ishga tushiriladi.",
      // title_ru:
      //   "В скором времени дистанционное заключение договора и подключение  услуг будет доступен.",
    },
    {
      name: "Havolalar",
      id: 24,
      uz: "Havolalar",
      ru: "Ссылки",
      children: [
        {
          name: "unicon.uz",
          id: 223,
          // ru: "главный",
          // uz: "Asosiy",
          ru: "unicon.uz",
          uz: "unicon.uz",
          href: "https://unicon.uz/",
        },
        {
          name: "Data markaz",
          id: 224,
          ru: "Дата центр",
          uz: "Data markaz",
          href: "https://dc.unicon.uz/",
        },
        {
          name: "STT",
          id: 225,
          ru: "БОС",
          uz: "STT",
          href: "https://stt2.unicon.uz/#/",
        },
      ]
    },
    {
      name: "Yangiliklar",
      id: 21,
      uz: "Yangiliklar",
      ru: "НОВОСТИ",
      path: "/news",
    },
    {
      name: "Xujjatlar",
      ru: "ДОКУМЕНТЫ",
      uz: "Xujjatlar",
      id: 15,
      path: "/docs"
    },
    {
      name: "Jixozlar",
      ru: "Оборудования",
      uz: "Jixozlar",
      id: 16,
      path: "/gadgets"
    },
    {
      name: "Murojaat",
      uz: "Murojaat",
      ru: "Обращение",
      id: 76,
      path: "/appeal",
    },
    {
      name: "Bog'lanish",
      uz: "Bog'lanish",
      ru: "Контакты",
      id: 77,
      path: "/connection",
    },
  ];
  const depthLevel = 0;
  const dispatch = useDispatch();
  const [navOpen, setNavOpen] = useState(false);
  const location1 = useLocation();
  const { location } = useSelector((state) => state.location);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const handleClick = () => {
    setNavOpen(!navOpen);
  };

  const handleChange = (e) => {
    dispatch(switchLang(e.target.value));
  };

  useEffect(() => {
    setNavOpen(false);
  }, [pathname]);

  const [move, setMove] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setMove(true);
      } else {
        setMove(false);
      }
    });
  }, []);

  return (
    <>
      <nav
        className={
          location1.pathname === "/" && move
            ? `py-5 md:hidden desktop z-50 fixed w-full text-[#fff] duration-300 ${
                move
                  ? "pt-5 bg-[#0004] text-[#fff]"
                  : `${pathname !== "/" && "bg-[#0004]"}`
              }`
            : location1.pathname !== "/"
            ? `py-5 md:hidden desktop z-50 fixed w-full text-[#fff] duration-300 ${
              move
                ? "pt-5 bg-[#0004] text-[#fff]"
                : `${pathname !== "/" && "bg-[#0007]"}`
            }`
            :`py-5 pt-5 md:hidden desktop fixed z-50 w-full text-[#fff] duration-300 ${
                move
                  ? "bg-[#0004] text-[#fff]"
                  : `${pathname !== "/" && ""}`
              }`
        }
      >

        <Swiper
          direction={"horizontal"}
          slidesPerView={"auto"}
          spaceBetween={30}
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className={`mySwiper w-full z-[999] ${move ? "-translate-y-5" : "-translate-y-5"}`}
        >
          <Tilt>
            <SwiperSlide>
              <a
                className={
                  location1.pathname === "/" && move
                    ? "hidden"
                    : "flex absolut h-[80px] lg:h-auto object-cover bg-[#464897]"
                }
                target={"_blank"}
                rel="noreferrer"
                href={"https://dc.unicon.uz/"}
              >
                <img
                  className={"mx-auto"}
                  width={1200}
                  src={Rek25}
                  alt={"chegirma 25%"}
                />
              </a>
            </SwiperSlide>
          </Tilt>
          <Tilt>
            <SwiperSlide>
              <a
                className={
                  location1.pathname === "/" && move
                    ? "hidden"
                    : "flex z-[999] h-[80px] lg:h-auto object-cover bg-[#464897]"
                }
                target={"_blank"}
                rel="noreferrer"
                href={"https://dc.unicon.uz/"}
              >
                <img
                  className={"mx-auto"}
                  width={1200}
                  src={Rek15}
                  alt={"chegirma 15%"}
                />
              </a>
            </SwiperSlide>
          </Tilt>
        </Swiper>

        {/* <div className={location1.pathname === "/" && move ? "bg-grey p-1 absolute top-0 overflow-x-hidden w-full" : "hidden"}>
          <p className="text-white animation mr-8">{t("test")}</p>
        </div> */}
        <div className="container mx-auto">
          <div className="flex items-center justify-between">
            <Link to="/" className="logo w-[190px] h-[25px]">
              <img src={logo} alt="" className="w-full h-full" />
            </Link>
            <div className="flex items-center gap-7">
              <div className="flex items-center gap-1">
                <div className="px-2 flex items-center gap-3">
                  <div
                    className={`w-[1px] h-9 ${
                      move ? "bg-white" : ""
                    }`}
                  ></div>
                  
                  <div
                    className={`w-[1px] h-9 ${
                      move ? "bg-white" : ""
                    }`}
                  ></div>
                </div>
                {/* <span className='text-3xl'>
                    <i className="ri-global-line"></i>
                </span> */}
                <select
                  className="outline-none text-xl font-bold bg-transparent"
                  defaultValue={location}
                  onChange={handleChange}
                >
                    <option
                      className="font-bold bg-logo"
                      style={{ display: location === "ru" || location === "en" ? "" : "none" }}
                      value="uz"
                    >
                      uz
                    </option>
                    <option
                      className="font-bold bg-logo"
                      style={{ display: location === "uz" || location === "en" ? "" : "none" }}
                      value="ru"
                    >
                      ru
                    </option>
                    {/* <option
                      className="font-bold bg-logo"
                      style={{ display: location === "uz" || location === "ru" ? "" : "none" }}
                      value="en"
                    >
                      en
                    </option> */}
                </select>
              </div>
            </div>
          </div>

          {/* border-t-[#3A375E] */}
          <div
            className={`xl2:pt-1 ${
              move
                ? "border-t-[1px] border-t-white"
                : ""
            }`}
          >
            <ul className="menus flex justify-end lg3:justify-start whitespace-nowrap flex-wrap">
              {menu_items.map((menu, index) => (
                <MenuItems items={menu} key={index} depthLevel={depthLevel} />
              ))}
            </ul>
          </div>
        </div>
      </nav>

      {/*mobile*/}
      <nav
        className={`py-1 w-full mobile`}
        style={{ padding: navOpen ? "1.9rem 0" : "" }}
      >
        <div className="container mx-auto">
          <div className="flex flex-col justify-between">
            <div className="flex justify-between items-center logos">
              <Link to="/" className="logo w-[190px] h-[25px] xs:w-[150px]">
                <img
                  src={logo}
                  alt="logo rasm"
                  className={`w-full h-full duration-300 ${
                    navOpen
                      ? "opacity-0 duration-300 delay-100"
                      : "duration-300 delay-100"
                  }`}
                />
              </Link>
              <div className={`flex items-center gap-5 relative xs:gap-3`}>
                <div
                  className={`flex justify-center items-center ${
                    navOpen ? "md:absolute md:right-12 language -z-10" : ""
                  }`}
                >
                  {/* <span className='text-3xl xs:text-2xl'>
                      <i className="ri-global-line"></i>
                  </span> */}
                  <select
                    className="outline-none text-xl font-bold bg-transparent"
                    defaultValue={location}
                    onChange={handleChange}
                  >
                    <option
                      className="font-bold"
                      style={{ display: location === "ru" || location === "en" ? "" : "none" }}
                      value="uz"
                    >
                      uz
                    </option>
                    <option
                      className="font-bold"
                      style={{ display: location === "uz" || location === "en" ? "" : "none" }}
                      value="ru"
                    >
                      ru
                    </option>
                    {/* <option
                      className="font-bold"
                      style={{ display: location === "uz" || location === "ru" ? "" : "none" }}
                      value="en"
                    >
                      en
                    </option> */}
                  </select>
                </div>
                <div
                  className={`menu-toggle pb-5 ${
                    navOpen ? "fixed z-[99999] top-1 right-5" : ""
                  }`}
                  onClick={handleClick}
                >
                  <div className={navOpen ? "hamBox hamBoxOpen" : "hamBox"}>
                    {/* <span
                      className={navOpen ? "lineTop spin" : "lineTop"}
                    ></span>
                    <span
                      className={navOpen ? "lineBottom spin" : "lineBottom"}
                    ></span> */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor"><path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z"></path></svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`left_nav fixed w-[50%] xs:w-[70%] duration-500 bg-white left-0 top-0 h-full z-[9999] overflow-y-scroll overflow-x-hidden delay-75 ${
                navOpen ? "left-0" : "-left-[100%] hidden"
              }`}
            >
              <div className="flex justify-center items-center gap-1 px-5 py-1 mt-4">
                <div className="px-2 border border-b-logo hidden items-center gap-3 rounded-lg">
                  <span className="text-3xl xs:text-2xl">
                    <i className="ri-search-line"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="search..."
                    className={"outline-none"}
                  />
                </div>
              </div>
              {navOpen && (
                <ul className="menus flex flex-col justify-center items-center md:h-[65%] xs:h-[75%]">
                  {menu_items.map((menu, index) => (
                    <MenuItems
                      items={menu}
                      key={index}
                      depthLevel={depthLevel}
                    />
                  ))}
                </ul>
              )}
            </div>
            <div
              className={`fixed top-0 left-0 w-full h-full pointer-events-none z-[999] duration-300`}
              style={{
                background: navOpen ? "rgba(0, 49, 76, 0.58)" : "",
              }}
            ></div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
