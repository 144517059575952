import React, { useEffect, useState } from "react";
import CommonSection from "../../components/common-section/CommonSection";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getNews6, getSingleNews } from "../../redux/actions/actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectCoverflow } from "swiper/modules";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import moment from "moment";
import parse from "html-react-parser";

export default function NewsDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { location } = useSelector((state) => state.location);
  const { singleNew } = useSelector((state) => state.news);
  const { content_ru, content_uz } = useSelector(
    (state) => state.news.singleNew
  );
  const { news } = useSelector((state) => state.news);

  const last_news = news?.result;
  const [width, setWidth] = useState(3);

  useEffect(() => {
    dispatch(getSingleNews(id));
    dispatch(getNews6());

    changeWindow();
  }, [id]);

  useEffect(() => window.scrollTo(0, 0), []);

  const changeWindow = () => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        return setWidth(1);
      } else if (window.innerWidth < 1024) {
        return setWidth(2);
      } else {
        return setWidth(3);
      }
    });
  };

  return (
    <>
      <CommonSection
        title={location === "uz" ? singleNew.title_uz : singleNew.title_ru}
        breadCrumb={location === "uz" ? singleNew.title_uz : singleNew.title_ru}
      />
      <section>
        <div className="container flex mx-auto py-5">
          <div className="w-1/4 pr-5 pt-5 lg:hidden">
            <div className="sticky top-0">
              <h3 className="text-2xl font-medium text-logo mb-8">
                {t("categories")}
              </h3>

              <div>
                {news &&
                  last_news?.map((item, idx) => (
                    <Link
                      className="flex min-h-[90px] pb-5 border-b border-[#ccc] mb-5"
                      to={`/news/${item.id}`}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <img
                        className="object-cover block h-[70px]"
                        width={"70"}
                        height={"70"}
                        src={`${item?.files[0]?.file}`}
                        alt="image"
                      />
                      <div className="pl-4">
                        <p className="text-logo hover:text-hover text-xs uppercase mb-2">
                          {location === "uz"
                            ? item?.title_uz?.substring(0, 70)
                            : item?.title_ru?.substring(0, 70)}
                        </p>
                        <div
                          className="text-logo-secondary hover:text-hover text-xs"
                          dangerouslySetInnerHTML={{
                            __html:
                              location === "uz"
                                ? item?.content_uz?.substring(3, 70)
                                : item?.content_ru?.substring(3, 70),
                          }}
                        />
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>

          <div className="w-3/4 pl-5 pt-5 hover:shadow-2xl" style={{ border: "1px solid #abb8c3" }}>
            {/* <p className="text-base mb-5 leading-10 mt-6">{t("vps_text_1")}</p>
            <p className="text-base mb-5 leading-10">{t("vps_text_2")}</p> */}

            <div className="images w-full gap-10 flex">
              <div className="right w-full">
                <Swiper
                  direction={"horizontal"}
                  pagination={{
                    clickable: true,
                  }}
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                  }}
                  effect={"coverflow"}
                  grabCursor={true}
                  centeredSlides={true}
                  spaceBetween={5}
                  modules={[Pagination, EffectCoverflow, Navigation]}
                  slidesPerView={"auto"}
                  navigation={true}
                  className="mySwiper w-full"
                >
                  {singleNew &&
                    singleNew?.files?.map((item, idx) => (
                      <SwiperSlide key={item.id}>
                        {item.is_video ? (
                          <iframe
                            src={`${item?.video_url}`}
                            width={"auto"}
                            height={"50vh"}
                            style={{
                              height: "230px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            frameBorder={0}
                            allowFullScreen={true}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          />
                        ) : (
                          <img
                            className="mx-auto"
                            src={`${item.file}`}
                            alt="img"
                            style={{
                              height: "auto",
                              width: "50vh",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
            <div className="content py-5 rounded-lg">
              <div className="flex flex-col gap-7 p-4 leading-10">
                {location === "uz"
                  ? parse(`${content_uz}`)
                  : parse(`${content_ru}`)}
                <h1 className="font-bold mt-5 text-end">
                  {moment(singleNew.date).format("DD-MM-YYYY HH:mm")}
                </h1>
              </div>
            </div>

            <hr className="mb-20 mt-10 border-[#f0f0f1] border" />
          </div>
        </div>
      </section>
    </>
  );
}
