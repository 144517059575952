import React, {useState} from 'react'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {sendContact} from "../../redux/actions/actions";
import Email from '../../assets/mail.svg'
import User from '../../assets/mail.svg'
import phone from '../../assets/mail.svg'
import Messege from '../../assets/mail.svg'
import './index.css'

export default function ConnectionSection() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {t} = useTranslation()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone_number, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!name || !email || !phone_number || !message) {
      toast.error('Ma\'lumotlar to\'liq kiritilmagan')
    } else {
      dispatch(sendContact({full_name: name, email, phone_number, message, source: "certificate.unicon.uz"}))
      toast.success('Muvaffaqiyatli yuborildi! ')
      navigate('/')
      setName('')
      setEmail('')
      setMessage('')
      setPhoneNumber('')     
      window.scrollTo(0, 0) 
    }
  }

  return (
    <section className='text-center'>
        <div className='connection pt-52 pb-52 md:py-20 container mb-20'>
            <h1 className='text-4xl text-logo mb-8'>{t('if-quest')}<br/><b>{t('call')}</b></h1>
            <p className='mb-10'>{t('contact-text')}<br/>{t('contact-text2')}</p>
            
            <form onSubmit={handleSubmit} className='flex flex-wrap gap-5 md:gap-3 mb-20'>
            <div className='relative w-[32%] flex-grow sm:w-full hover:scale-105 duration-300'>
              <img width={'18px'} src={User} alt="user icon" className='absolute top-5 left-3 md:top-3'/>
              <input
                required
                value={name}
                onChange={e => setName(e.target.value)}
                className='hover:shadow-xl text-xl  px-4 py-4  border-none md:px-2 md:py-2 w-full pl-9 md:pl-9'
                style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}
                type="text"
                placeholder={t('organization-name')}
                name='name'
              />
            </div>

            <div className='relative w-[32%] flex-grow sm:w-full hover:scale-105 duration-300'>
              <img width={'18px'} src={Email} alt="email icon" className='absolute top-5 left-3 md:top-3'/>
              <input
                required value={email} onChange={e => setEmail(e.target.value)}
                className='hover:shadow-xl text-xl  px-4 py-4 border-none md:px-2 md:py-2 w-full pl-9 md:pl-9'
                style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}
                type="email"
                placeholder={t('email')}
                name='email'
              />
            </div>

            <div className='relative w-[32%] flex-grow sm:w-full hover:scale-105 duration-300'>
              <img width={'18px'} src={phone} alt="phone icon" className='absolute top-5 left-3 md:top-3'/>
              <input /* mask={'(99) 999 99 99'} */
                value={phone_number} onChange={e => setPhoneNumber(e.target.value)} required
                className='hover:shadow-xl text-xl  px-4 py-4 border-none md:px-2 md:py-2 w-full pl-9 md:pl-9'
                style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}
                type="tel"
                placeholder={t('phone')} name='phone_number'
              />
            </div>

            <div className='relative w-[66%] flex-grow sm:w-full hover:scale-105 duration-300'>
              <img width={'18px'} src={Messege} alt="messege icon" className='absolute top-5 left-3 md:top-3'/>
              <textarea
                required
                value={message}
                onChange={e => setMessage(e.target.value)}
                className='hover:shadow-xl text-xl  px-4 py-4 border-none w-full h-full md:px-2 md:py-2 pl-9 md:pl-9'
                style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}
                placeholder={t('message')}
                name={t('message')}
              />
            </div>

            <div className='flex flex-col w-[32%] flex-grow items-start'>
              <button
                className='px-6 text-xl py-2 bg-logo border-logo border-2 border-solid hover:bg-white  text-white hover:text-logo font-semibold rounded-[4px] mt-3  md:px-2 md:mt-2 transition'
                type={'submit'}
              >
                {t('send')}
              </button>
            </div>
          </form>
        </div>
    </section>
  )
}
