import {
  SWITCH_LANG,
  SEND_CONTACT,
  GET_ELEMENT,
  SEND_ELEMENTS,
  TYPING,
  INITIAL_STATE,
  GET_NEWS,
  GET_SINGLE_NEWS,
  GET_STATISTICS,
  GET_PROTCES_SERTIFICATION,
  GET_DOC,
  GET_COSTUMERS,
  GET_GADGETS, GET_SINGLE_GADGET,
  APPEAL,
  GET_COMPASSES_CONTACT,
  MOST_QUESTIONS,
  GET_SINGLE_ORG,
} from "../constants/constants";
import i18next from "i18next";
import axios from "axios";
const baseURL = "https://back.unicon.uz/api"

export const switchLang = (lng) => async (dispatch) => {
    await i18next.changeLanguage(lng)
    dispatch({
      type: SWITCH_LANG,
      payload: lng
    })
}
  
export const sendContact = (items) => async (dispatch) => {
    try {
      const response = await axios.post(`${baseURL}/contact`, items)
      dispatch({
        type: SEND_CONTACT,
        payload: response.data
      })
    } catch (e) {
      console.log(e)
    }
}

export const getNews = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseURL}/cat/cer`);
    dispatch({
      type: GET_NEWS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getNews6 = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseURL}/cat/cer?last_count=6`);
    dispatch({
      type: GET_NEWS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getSingleNews = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseURL}/cat/detail/${id}`
    );
    dispatch({
      type: GET_SINGLE_NEWS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getGadgets = (page = 1) => async (dispatch) => {
  try {
    const response = await axios.get(`${baseURL}/sertification/lab-instruments/?page_size=${page}`);
    dispatch({
      type: GET_GADGETS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getSingleGadget = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseURL}/sertification/lab-instruments/${id}`
    );
    dispatch({
      type: GET_SINGLE_GADGET,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getStatistics = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseURL}/sertification/certification-stats`);
    dispatch({
      type: GET_STATISTICS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getProtcesSertification = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseURL}/sertification/certification`);
    dispatch({
      type: GET_PROTCES_SERTIFICATION,
      payload: data,
    })
  } catch (e) {
    console.log(e)
  }
}

export const getDoc = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseURL}/sertification/cer-documents`
    );
    dispatch({
      type: GET_DOC,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getCostumers = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseURL}/sertification/our-clients`);
    dispatch({
      type: GET_COSTUMERS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const postAppeal = (items) => async (dispatch) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const response = await axios.post(
      `${baseURL}/sertification/appeal/`,
      items,
      { headers: headers }
    );
    dispatch({
      type: APPEAL,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getCompassesContact = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseURL}/sertification/cer-contact`);
    dispatch({
      type: GET_COMPASSES_CONTACT,
      payload: data,
    })
  } catch (e) {
    console.log(e)
  }
}

export const getMostQuestions = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseURL}/sertification/question-answers`
    );
    dispatch({
      type: MOST_QUESTIONS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getSingleOrg = (slug) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseURL}/sertification/organs/${slug}`
    );
    dispatch({
      type: GET_SINGLE_ORG,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};