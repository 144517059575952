import Layout from "../layout";
import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import AboutUs from "../pages/about-us";
import Connection from "../pages/Connection";
import Services from "../pages/services";
import NewsPage from "../pages/news/news";
import NewsDetails from "../pages/news/newsDetail";
import GetDocs from "../pages/docs";
import Gadgets from "../pages/gadgets/gadgets";
import GadgetDetails from "../pages/gadgets/gadget-details";
import Appeal from "../pages/appeal";
import MostQuestions from "../pages/about-us/most-questions/most-questions";
import SertificationOrgs from "../pages/about-us/sertification-orgs/sertification-orgs";

const ErrorPage = () => {
    return <div>Oops! Something went wrong.</div>;
  };

export const routers = createBrowserRouter([
    {
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                element: <Home />,
                path: '/',
            },
            {
                element: <AboutUs />,
                path: '/about-us',
            },
            {
                element: <NewsPage />,
                path: '/news',
            },
            {
                element: <NewsDetails />,
                path: 'news/:id',
            },
            {
                element: <Connection />,
                path: '/connection',
            },
            {
                element: <Appeal />,
                path: '/appeal',
            },
            {
                element: <Services />,
                path: '/services',
            },
            {
                element: <GetDocs />,
                path: '/docs',
            },
            {
                element: <Gadgets />,
                path: '/gadgets',
            },
            {
                element: <GadgetDetails />,
                path: 'gadget/:id',
            },
            {
                element: <MostQuestions />,
                path: '/most-questions',
            },
            {
                element: <SertificationOrgs />,
                path: '/:slug',
            },
        ]

    }
])