import React, { useEffect, useState } from "react";
import CommonSection from "../../components/common-section/CommonSection";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getGadgets, getNews } from "../../redux/actions/actions";
import moment from "moment";
import Pagination from "../../components/Pagination/Pagination";
import Image from "../../assets/no-results-bg.2d2c6ee3.png";

function Gadgets() {
  const dispatch = useDispatch();
  const { gadgets } = useSelector((state) => state.gadgets);
  const { location } = useSelector((state) => state.location);
  const { t } = useTranslation();

  const [hoveredItemId, setHoveredItemId] = useState(null);

  useEffect(() => {
    dispatch(getGadgets());
  }, []);

  useEffect(() => window.scrollTo(0, 0), []);

  const handlePageChange = (page) => {
    dispatch(getGadgets(page));
  };

  return (
    <>
      <CommonSection title={location === "uz" ? "Jihozlar" : "Оборудования"} />
      {gadgets?.result?.length === 0 || gadgets?.length === 0 ? (
        <div className={"mx-auto w-full text-center pb-10"}>
          <img src={Image} alt="" className={"w-1/4 mx-auto"} />
          <h1 className={"text-3xl"}>{t("soon")}</h1>
        </div>
      ) : (
        <section id="news" className="pt-20 mb-20">
          <div className="container">
            <h2 className="text-4xl font-bold text-logo mb-16 text-center">
              {location === "uz" ? "Jihozlar" : "Оборудования"}
            </h2>
            <div className="flex flex-wrap justify-start gap-[3%] lg:justify-evenly mb-10">
              {gadgets &&
                gadgets?.result?.map((e, i) => (
                  <Link
                    key={i}
                    to={`/gadget/${e.id}`}
                    className="w-[31%] lg:w-5/12 news-card shadow-2xl mb-10 relative mr-1 overflow-hidden"
                  >
                    <div className="relative">
                      {e?.lab_data[0]?.is_video ? (
                        <iframe
                          src={`${e?.files[0]?.video_url}`}
                          title="gadget"
                          width={"100%"}
                          height={"100%"}
                          style={{
                            height: "230px",
                            width: "100%",
                            objectFit: "cover",
                            backgroundColor: "#ccc",
                          }}
                          frameBorder={0}
                          allowFullScreen={true}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                      ) : (
                        <img
                          className="h-80 object-cover bg-bd"
                          width={"1024"}
                          height={"768"}
                          src={`${e?.lab_data[0]?.file}`}
                          alt="img"
                        />
                      )}
                      <div className="absolute top-3 left-3 bg-hover text-white font-bold uppercase rounded-lg p-1 px-2">
                        {moment(e.date).format("DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                    <div className="p-8">
                      <h3
                        className="text-xl hover:text-hover font-semibold transition-colors mb-4"
                        dangerouslySetInnerHTML={{
                          __html:
                            location === "uz"
                              ? e.title_uz.substring(0, 75)
                              : e.title_ru.substring(0, 75),
                        }}
                      />

                      {/* <p>{e.text}</p> */}
                      <div
                        // className="text-logo-secondary hover:text-hover text-xs"
                        dangerouslySetInnerHTML={{
                          __html:
                            location === "uz"
                              ? e?.content_uz?.substring(3, 210)
                              : e?.content_ru?.substring(3, 210),
                        }}
                      />
                    </div>
                  </Link>
                ))}
            </div>
            <div className={"flex justify-center pb-3"}>
              <Pagination
                totalItems={gadgets?.count}
                itemsPerPage={12}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Gadgets;
