export const SWITCH_LANG = 'SWITCH_LANG'
export const SEND_CONTACT = 'SEND_CONTACT'
export const SEND_ELEMENTS = 'SEND_ELEMENTS'
export const GET_ELEMENT = 'GET_ELEMENT'
export const TYPING = 'TYPING'
export const INITIAL_STATE = "INITIAL_STATE"
export const GET_NEWS = "GET_NEWS"
export const GET_SINGLE_NEWS = "GET_SINGLE_NEWS"
export const GET_STATISTICS = "GET_STATISTICS"
export const GET_PROTCES_SERTIFICATION = "GET_PROTCES_SERTIFICATION"
export const GET_DOC = "GET_DOC"
export const GET_COSTUMERS = "GET_COSTUMERS"
export const GET_GADGETS = "GET_GADGETS"
export const GET_SINGLE_GADGET = "GET_SINGLE_GADGET"
export const APPEAL = "APPEAL"
export const GET_COMPASSES_CONTACT = "GET_COMPASSES_CONTACT"
export const MOST_QUESTIONS = "MOST_QUESTIONS"
export const GET_SINGLE_ORG = "GET_SINGLE_ORG"
