import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import BgVd from "../../../../assets/bgVd.mp4"
import "swiper/swiper-bundle.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "../../index.css"
import { useSelector } from "react-redux";

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

const VideosAboutUs = () => {
  const { location } = useSelector((state) => state.location);

  const [state, setState] = useState(false);
  const [state1, setState1] = useState(false);

  return (
    <section className="unicon-info mt-10">
      <div className="container ">
        <Swiper
          direction={"horizontal"}
          slidesPerView={1}
          spaceBetween={30}
          modules={[Navigation, Pagination]}
          effect="fade"
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            type: "bullets",
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          // scrollbar={{draggable: true}}
          // className="mySwiper w-full"
          className="clientSwiper w-full hover:shadow-2xl"
        >
          <Tilt>
            <SwiperSlide>
              <div
                style={{
                  boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)",
                }}
                className="videorolik flex flex-col gap-1 w-full relative overflow-hidden cursor-pointer"
                onClick={() => setState(true)}
              >
                <video
                  className="videoUnicon absolute w-full object-cover"
                  src={BgVd}
                  loop={true}
                  autoPlay={true}
                  muted
                />

                <div className="videorolik__link cursor-pointer">
                  <div className="round1">
                    <div className="round2">
                      <svg
                        width="180"
                        height="180"
                        viewBox="0 0 212 212"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M66.9124 50.4207C65.0896 68.8757 64.1993 87.411 64.2447 105.956C64.2447 130.698 65.7464 150.096 66.9124 161.509C83.8032 153.841 100.298 145.33 116.335 136.007C132.422 126.782 148.035 116.756 163.116 105.965C148.037 95.1643 132.427 85.1258 116.344 75.8872C100.302 66.5784 83.8044 58.0789 66.9124 50.4207ZM50.0849 42.0467C50.3217 40.0401 51.0238 38.1166 52.1352 36.4293C53.2467 34.7421 54.7368 33.3376 56.4869 32.3279C58.2369 31.3182 60.1986 30.7311 62.2156 30.6134C64.2326 30.4956 66.2493 30.8506 68.1049 31.6498C77.4859 35.6602 98.5092 45.1913 125.186 60.5878C151.871 75.9932 170.642 89.4463 178.795 95.5502C185.756 100.771 185.774 111.123 178.804 116.362C170.731 122.43 152.189 135.706 125.186 151.306C98.1559 166.906 77.3799 176.322 68.0872 180.279C60.0842 183.698 51.1272 178.513 50.0849 169.883C48.8659 159.795 46.5869 136.89 46.5869 105.956C46.5869 75.0392 48.8571 52.1432 50.0849 42.0467Z"
                          fill="white"
                          fillOpacity="0.95"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <h2 className="videorolik__title uppercase">
                  {location === "uz"
                    ? "“Unicon.uz” MChJ qoshidagi TTVS laboratoriyasidan video lavha"
                    : location === "ru"
                    ? "Видео-обзор лаборатории СИЦ ТСТ при ООО \"UNICON.UZ\""
                    : "Video review of the laboratory of the SIC TST at LLC \"UNICON.UZ\""}
                </h2>
              </div>
            </SwiperSlide>
          </Tilt>
          <Tilt>
            <SwiperSlide>
              <div
                style={{
                  boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)",
                }}
                className="videorolik flex flex-col gap-1 w-full relative overflow-hidden cursor-pointer"
                onClick={() => setState1(true)}
              >
                <video
                  className="videoUnicon absolute w-full object-cover"
                  src={BgVd}
                  loop={true}
                  autoPlay={true}
                  muted
                />

                <div className="videorolik__link cursor-pointer">
                  <div className="round1">
                    <div className="round2">
                      <svg
                        width="180"
                        height="180"
                        viewBox="0 0 212 212"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M66.9124 50.4207C65.0896 68.8757 64.1993 87.411 64.2447 105.956C64.2447 130.698 65.7464 150.096 66.9124 161.509C83.8032 153.841 100.298 145.33 116.335 136.007C132.422 126.782 148.035 116.756 163.116 105.965C148.037 95.1643 132.427 85.1258 116.344 75.8872C100.302 66.5784 83.8044 58.0789 66.9124 50.4207ZM50.0849 42.0467C50.3217 40.0401 51.0238 38.1166 52.1352 36.4293C53.2467 34.7421 54.7368 33.3376 56.4869 32.3279C58.2369 31.3182 60.1986 30.7311 62.2156 30.6134C64.2326 30.4956 66.2493 30.8506 68.1049 31.6498C77.4859 35.6602 98.5092 45.1913 125.186 60.5878C151.871 75.9932 170.642 89.4463 178.795 95.5502C185.756 100.771 185.774 111.123 178.804 116.362C170.731 122.43 152.189 135.706 125.186 151.306C98.1559 166.906 77.3799 176.322 68.0872 180.279C60.0842 183.698 51.1272 178.513 50.0849 169.883C48.8659 159.795 46.5869 136.89 46.5869 105.956C46.5869 75.0392 48.8571 52.1432 50.0849 42.0467Z"
                          fill="white"
                          fillOpacity="0.95"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <h2 className="videorolik__title uppercase">
                  {location === "uz"
                    ? "Kafolatlangan muvofiqlik sertifikati"
                    : location === "ru"
                    ? "Гарантированный сертификат соответствия"
                    : "Guaranteed Certificate of Conformity"}
                </h2>
              </div>
            </SwiperSlide>
          </Tilt>
        </Swiper>
        <div className="button-Atrrangment">
          <div className="button-swiper">
            <div className="swiper-button-prev"></div>
            <div className="swiper-pagination"></div>
            <div className="swiper-button-next"></div>
          </div>
        </div>

        {state1 && (
          <div
            className="video-wrap"
            onClick={() => setState1(false)}
            style={{
              display: state1 ? "flex" : "",
            }}
          >
            <div className="video-wrap__modal">
              <iframe
                width="100%"
                height={500}
                src="https://www.youtube.com/embed/Z4jxo-MNpUk?si=faDbWaVex1VKvjK2"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        )}

        {state && (
          <div
            className="video-wrap"
            onClick={() => setState(false)}
            style={{
              display: state ? "flex" : "",
            }}
          >
            <div className="video-wrap__modal">
              <iframe
                width="100%"
                height={500}
                src="https://www.youtube.com/embed/PzvUbEXbU0M?si=jK6oMASxgkQZKcwP"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default VideosAboutUs;
