import React from 'react'
import CommonSection from '../../components/common-section/CommonSection'
import {useTranslation} from "react-i18next";

export default function AboutUs() {
  const {t} = useTranslation()

  return (
    <>
      <CommonSection title={t('about')} breadCrumb={t('about')} />
      <div className='pt-20'></div>
      <div className='container  pb-40'>
        <p className='text-base mb-5 leading-10'>{t('about_text_2-new')}</p>
        <p className='text-base mb-5 leading-10'>{t('about_text_3-new')}</p>
        <p className='text-base mb-5 leading-10'>{t('about_text_4-new')}</p>
        <p className='text-base mb-5 leading-10'>{t('about_text_5-new')}</p>
        <p className='text-base mb-5 leading-10'>{t('about_text_6-new')}</p>
      </div>
    </>
  )
}
