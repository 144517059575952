import React from "react";
import CommonSection from "../../components/common-section/CommonSection";
import { useTranslation } from "react-i18next";
import "./index.css";
import Image from "../../assets/no-results-bg.2d2c6ee3.png";

export default function Services() {
  const { t } = useTranslation();

  return (
    <>
      <CommonSection title={t("servises")} breadCrumb={t("servises")} />
      <section className="pt-20">
        <div className="container">

          <div className={"mx-auto w-full text-center mb-10"}>
            <img src={Image} alt="" className={"w-1/4 mx-auto"} />
            <h1 className={"text-3xl"}>{t("soon")}</h1>
          </div>

        </div>
      </section>
    </>
  );
}
