import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension'

import { changeReducer, elementReducer, langReducer, newsReducer, statisticsReducer, protcesSertificationReducer, getDocReducer, costumersReducer, getgetsReducer, compassesContactReducer, getMostQuestionsReducer, getOrgReducer } from './reducers/reducers'

const reducers = combineReducers({
  location: langReducer,
  element: elementReducer,
  inputs: changeReducer,
  news: newsReducer,
  statistics: statisticsReducer,
  protcesSertification: protcesSertificationReducer,
  doc: getDocReducer,
  costumers: costumersReducer,
  gadgets: getgetsReducer,
  compassesContact: compassesContactReducer,
  mostQuestions: getMostQuestionsReducer,
  singleOrg: getOrgReducer,
})

const middleware = [thunk]

const store = createStore(
  reducers,
  {},
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store