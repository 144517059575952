import React, { useState } from "react";
import CommonSection from "../../../components/common-section/CommonSection";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {getMostQuestions} from "../../../redux/actions/actions";
import { useTranslation } from "react-i18next";
import Image from "../../../assets/no-results-bg.2d2c6ee3.png";

export default function MostQuestions() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { location } = useSelector((state) => state.location);
  const { mostQuestions } = useSelector((state) => state.mostQuestions);

  const [openIndex, setOpenIndex] = useState(null);
  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    dispatch(
      getMostQuestions()
    );
  }, []);

  return (
    <section className="">
      <CommonSection
        title={ location === "uz" ? "Ko'p beriladigan savollar" : "Часто задаваемые вопросы" }
        breadCrumb={ location === "uz" ? "Ko'p beriladigan savollar" : "Часто задаваемые вопросы" }
      />

      <div className="container mx-auto py-5">
        {
          mostQuestions &&
          mostQuestions?.map((item, index) => (
            <div key={index} onClick={() => handleToggle(index)}>
              <div
                className={`px-2 py-4 mt-2 cursor-pointer flex items-center justify-between ${
                  openIndex === index
                    ? "bg-logo text-white"
                    : "bg-accordion text-dark"
                } duration-200`}
              >
                <div
                  className={""}
                  dangerouslySetInnerHTML={{
                    __html: location === "uz" ? item?.title_uz : item?.title_ru,
                  }}
                />
                {openIndex === index ? (
                  <h1 className={"text-3xl"}>-</h1>
                ) : (
                  <h1 className={"text-3xl"}>+</h1>
                )}
              </div>
              {openIndex === index && (
                <div
                  className={
                    "bg-accordion_content px-4 py-2 w-[99%] m-auto flex flex-col gap-2"
                  }
                >
                  <div
                    className={""}
                    dangerouslySetInnerHTML={{
                      __html:
                        location === "uz" ? item?.text_uz : item?.text_ru,
                    }}
                  />
                </div>
              )}
            </div>
          ))}
        {mostQuestions.length === 0 && (
          <div className={"mx-auto w-full text-center"}>
            <img src={Image} alt="" className={"w-1/4 mx-auto"} />
            <h1 className={"text-3xl"}>{t("soon")}</h1>
          </div>
        )}
      </div>
    </section>
  );
}