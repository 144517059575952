import React, {useEffect, useState} from "react";
import { SystemContextProvider } from "./context";
import { Routers } from "./routes/routes";
import {ReactComponent as ArrowUp} from "./assets/arrow-up.svg"


function App() {
  const [toTop, setToTop] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
        setToTop(true)
      } else {
        setToTop(false)
      }
    })
  }, [])

  return (
    <SystemContextProvider>
      <div className="App h-full">
        <div
          onClick={() => window.scrollTo(0, 0)}
          className='hover:-translate-y-2 duration-200 fixed z-50 bottom-8 right-10 xs:right-4 xs:bottom-10 bg-white w-12 h-12 rounded-full flex justify-center items-center cursor-pointer'
          style={{
            opacity: toTop ? 1 : 0,
            pointerEvents: toTop ? 'all' : 'none',
            boxShadow: "0px 7.94708px 24px -2px rgba(0, 0, 0, 0.25)"
          }}
        >
          <span className='text-7xl text-[#00314C]'>
              <ArrowUp />
          </span>
        </div>
        
        <Routers />
      </div>
    </SystemContextProvider>
  );
}

export default App;
