import React from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, EffectFade} from "swiper/modules";

import 'swiper/css';
import 'swiper/css/effect-fade';
import './index.css'

export default function Hero() {
  const {t} = useTranslation()

  return (
    <section className='hero relative overflow-hidden h-[94vh] lg1:h-[84vh]'>
      {/*<video className='h-screen w-full object-cover' loop={true} autoPlay={true} muted >*/}
      {/*  <source className='h-screen' src={HeroVideo} type="video/mp4" />*/}
      {/*</video>*/}

      {/*<div className='w-full absolute z-40 top-0 h-screen hero-bg'>*/}
      {/*  <div className='container pb-40 pt-72 lg:pt-48 h-screen flex flex-col items-start'>*/}
      {/*    <h1 className='text-5xl md:text-3xl font-semibold mt-auto mb-10 text-white'>{t('duk')}<br/> <span*/}
      {/*      className='text-hover'>{t('dc-site')}</span> <br/>{t('dc-welcome')}</h1>*/}
      {/*    <Link to={'/about-us'}*/}
      {/*          className='hover:bg-hover transition-all active:opacity-80 text-white px-8 py-4 md:px-6 md:py-3 my-5 font-semibold  rounded-full border-2 border-hover hover:border-white border-solid text-xl md:text-lg uppercase'>{t('more')}</Link>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <Swiper
        autoplay={{
          delay: 3000,
        }}
        modules={[Autoplay, EffectFade]}
        effect={'fade'}
        loop={false}
        slidesPerView={1}
        className={'relative swiper'}
      >
        <SwiperSlide>
          <div
            className="absolute top-0 left-0 z-20 w-screen h-screen"
            style={{backgroundColor: "rgba(0, 0, 0, 0.5)"}}
          />
          <div className={'w-screen h-screen slide_1 flex'}>
            {/* <h1 className={'text-white z-50 relative container flex items-center text-3xl uppercase font-bold'}>
              ТЕЗКОР-ҚИДИРУВ ТАДБИРЛАР ТИЗИМИ ТЕХНИК ВОСИТАЛАРИНИ
              СЕРТИФИКАТЛАШТИРИШ МАХСУС ИДОРАСИ (ТҚТТ ТВ СМИ)
            </h1> */}
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div
            className="absolute top-0 left-0 z-20 w-screen h-screen"
            style={{backgroundColor: "rgba(0, 0, 0, 0.5)"}}
          />
          <div className={'w-screen h-screen slide_2 flex'}>
            <h1 className={'text-white z-50 relative container flex items-center text-3xl uppercase font-bold'}>
              ТҚТТ ТВ сертификатлаштириш ишларини 3-схема бўйича ташкил этиш ва ўтказиш
            </h1>
          </div>
        </SwiperSlide> */}
        <SwiperSlide>
          <div
            className="absolute top-0 left-0 z-20 w-screen h-screen"
            style={{backgroundColor: "rgba(0, 0, 0, 0.5)"}}
          />
          <div className={'w-screen h-screen slide_3 flex'}>
            {/* <h1 className={'text-white z-50 relative container flex items-center text-3xl uppercase font-bold'}>
              сертификатлаштирилган ТҚТТ ТВ ишлаб чиқариш барқарорлиги ва хусусиятларини инспекция назоратини олиб бориш
            </h1> */}
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}
