import React, { useContext, useEffect } from "react";
import Advantages from "./components/advantages";
import News from "./components/news";
import Hero from "./components/hero";
import ConnectionSection from "../../components/connection/index";
import { SystemContext } from "../../context";
import Statistics from "./components/statistics/statistics";
import ProtcesSertification from "./components/protcess-sertification/protcess-sertification";
import RekModal from "../../components/modal/rekModal";
import Costumers from "./components/costumers";
import UsefulPages from "./components/useful-pages/useful-pages";
import VideosAboutUs from "./components/videos-about-us/videos-about-us";

export default function Home() {
  const { hasScrollElement } = useContext(SystemContext);

  useEffect(() => {
    let element = document.getElementById("news");
    let rect = element.getBoundingClientRect();
    let distance_from_top = rect.top; /* 50px */

    function scrollTovView() {
      window.scrollTo({
        top: distance_from_top,
        behavior: "smooth",
      });
    }
    if (hasScrollElement) {
      scrollTovView();
    }
  }, []);

  return (
    <div className="flex-grow">
      <Hero />
      <Statistics />
      <ProtcesSertification />
      <VideosAboutUs />
      {/* <Advantages /> */}
      <News />
      <ConnectionSection />
      <RekModal />
      {/* <UsefulPages /> */}
      <Costumers />
    </div>
  );
}
