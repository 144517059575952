import React from "react";

const usefulPagesInfo = [
    {icon: "", info: "Xizmatlar", href: "https://xizmatlar.unicon.uz/"},
    {icon: "", info: "Unicon.uz", href: "https://unicon.uz/"},
]

const UsefulPages = () => {
  return (
    <section className="useful-pages">
      <div className="container">
        <h2 className="text-4xl font-bold text-logo mb-16 text-center">
          Foydali sahifalar
        </h2>
        <div className="useful-pages__cards-wrapper">
            {usefulPagesInfo.map((e) => (
                <a target="_blank" className="w-80 rounded p-5 shadow hover:shadow-lg mr-5" href={e.href} >{e.info}</a>
            ))}
        </div>
      </div>
    </section>
  );
};

export default UsefulPages;
