import React, { useState } from "react";
import CommonSection from "../../components/common-section/CommonSection";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {getDoc} from "../../redux/actions/actions";
import { useTranslation } from "react-i18next";
import Image from "../../assets/no-results-bg.2d2c6ee3.png";

export default function GetDocs() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { location } = useSelector((state) => state.location);
  const { getDocs } = useSelector((state) => state.doc);

  const [openIndex, setOpenIndex] = useState(null);
  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    dispatch(
      getDoc()
    );
  }, []);

  return (
    <section className="">
      <CommonSection
        title={ location === "uz" ? "Xujjatlar" : "документы" }
        breadCrumb={ location === "uz" ? "Xujjatlar" : "документы" }
      />

      <div className="container mx-auto py-5">
        {
          getDocs &&
          getDocs?.map((item, index) => (
            <div key={index} onClick={() => handleToggle(index)}>
              <div
                className={`px-2 py-4 mt-2 cursor-pointer flex items-center justify-between ${
                  openIndex === index
                    ? "bg-logo text-white"
                    : "bg-accordion text-dark"
                } duration-200`}
              >
                <div
                  className={""}
                  dangerouslySetInnerHTML={{
                    __html: location === "uz" ? item?.name_uz : item?.name_ru,
                  }}
                />
                {openIndex === index ? (
                  <h1 className={"text-3xl"}>-</h1>
                ) : (
                  <h1 className={"text-3xl"}>+</h1>
                )}
              </div>
              {openIndex === index && (
                <div
                  className={
                    "bg-accordion_content px-4 py-2 w-[99%] m-auto flex flex-col gap-2"
                  }
                >
                  <div
                    className={""}
                    dangerouslySetInnerHTML={{
                      __html:
                        location === "uz" ? item?.content_uz : item?.content_ru,
                    }}
                  />
                  <a
                    rel="noreferrer"
                    title="yuklash"
                    className="ml-auto px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
                    href={item.file}
                    target={"_blank"}
                  >
                    {t("download")}
                  </a>
                </div>
              )}
            </div>
          ))}
        {getDocs.length === 0 && (
          <div className={"mx-auto w-full text-center"}>
            <img src={Image} alt="" className={"w-1/4 mx-auto"} />
            <h1 className={"text-3xl"}>{t("soon")}</h1>
          </div>
        )}
      </div>
    </section>
  );
}
