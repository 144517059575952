import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getNews6 } from "../../../../redux/actions/actions";
import moment from "moment";
import "./index.css";

export default function News({ ref }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { location } = useSelector((state) => state.location);
  const { news } = useSelector((state) => state.news);

  useEffect(() => {
    dispatch(getNews6());
  }, []);

  const last_news = news?.result?.slice(0, 3);

  return (
    <section id="news" className="pt-20 mb-20" ref={ref}>
      <div className="container">
        <h2 className="text-4xl font-bold text-logo mb-16 text-center">
          {t("news")}
        </h2>
        <div className="flex flex-wrap justify-start gap-[3%] lg:justify-evenly mb-10">
          {last_news &&
            last_news.map((e, i) => (
              <Link
                key={i}
                to={`/news/${e.id}`}
                className="w-[31%] lg:w-5/12 news-card shadow-2xl mb-10 relative overflow-hidden"
              >
                <div className="relative">
                  {e?.files[0]?.is_video ? (
                    <iframe
                      src={`${e?.files[0]?.video_url}`}
                      width={"100%"}
                      height={"100%"}
                      style={{
                        height: "230px",
                        width: "100%",
                        objectFit: "cover",
                        backgroundColor: "#ccc",
                      }}
                      frameBorder={0}
                      allowFullScreen={true}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                  ) : (
                    <img
                      className="h-80 object-cover bg-bd"
                      width={"1024"}
                      height={"768"}
                      src={`${e?.files[0]?.file}`}
                      alt="img"
                    />
                  )}
                  <div className="absolute top-3 left-3 bg-hover text-white font-bold uppercase rounded-lg p-1 px-2">
                    {moment(e.date).format("DD-MM-YYYY HH:mm")}
                  </div>
                </div>
                <div className="p-8">
                  <h3 className="text-xl hover:text-hover font-semibold transition-colors mb-4">
                    {`${
                      location === "uz"
                        ? e.title_uz.substring(0, 75)
                        : e.title_ru.substring(0, 75)
                    }...`}
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        location === "uz"
                          ? e?.content_uz?.substring(3, 210)
                          : e?.content_ru?.substring(3, 210),
                    }}
                  />
                </div>
              </Link>
            ))}
        </div>
      </div>
    </section>
  );
}
