import {
  SWITCH_LANG,
  GET_ELEMENT,
  TYPING,
  INITIAL_STATE,
  GET_NEWS,
  GET_SINGLE_NEWS,
  GET_STATISTICS,
  GET_PROTCES_SERTIFICATION, GET_DOC, GET_COSTUMERS, GET_GADGETS, GET_SINGLE_GADGET,
  GET_COMPASSES_CONTACT,
  MOST_QUESTIONS,
  GET_SINGLE_ORG
} from "../constants/constants";

const lang = {
    location: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : "uz"
}
const initialStateElement = {
  element: {},
}
const initialStateInput = {
  inputs: {}
}

const initialStateNews = {
  news: [],
  singleNew: {},
  loading: false,
};

const initialStateGadgets = {
  gadgets: [],
  singleGadget: {},
  loading: false,
};

const initialStateStatistics = {
  statistics: [],
  loading: false,
};

const initialStateProtcessSertification = {
  protcesSertification: [],
  loading: false,
}

const initialStateDoc = {
  getDocs: [],
  loading: false,
}

const initialStateCostumers = {
  costumers: [],
  loading: false,
}

const initialStateCompassesContact = {
  compassesContact: [],
  loading: false,
}

const initialStateMostQuestions = {
  mostQuestions: [],
  loading: false,
}

const initialStateOrg = {
  singleOrg: {},
  loading: false,
};


  export const langReducer = (state = lang, action) => {
    switch (action.type) {
      case SWITCH_LANG:
        return {
          ...state,
          location: action.payload
        }
      default:
        return state
    }
}

export const changeReducer = (state = initialStateInput, action) => {
  const {type, payload} = action
  switch(type) {
    case TYPING:
      return {
        inputs: {
          ...state.inputs,
          [payload.name]: payload.value
        },
      }
    case INITIAL_STATE:
      return initialStateInput
    default:
      return state
  }
}

export const elementReducer = (state = initialStateElement, action) => {
  switch (action.type) {
    case GET_ELEMENT:
      return {
        ...state,
        element: action.payload,
      }
    default:
      return state
  }
}

export const newsReducer = (state = initialStateNews, action) => {
  switch (action.type) {
    case GET_NEWS:
      return {
        ...state,
        news: action.payload,
        loading: false,
      };
    case GET_SINGLE_NEWS:
      return {
        ...state,
        singleNew: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const getgetsReducer = (state = initialStateGadgets, action) => {
  switch (action.type) {
    case GET_GADGETS:
      return {
        ...state,
        gadgets: action.payload,
        loading: false,
      };
    case GET_SINGLE_GADGET:
      return {
        ...state,
        singleGadget: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const statisticsReducer = (state = initialStateStatistics, action) => {
  switch (action.type) {
    case GET_STATISTICS:
      return {
        ...state,
        statistics: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const protcesSertificationReducer = (state = initialStateProtcessSertification, action) => {
  switch (action.type) {
    case GET_PROTCES_SERTIFICATION:
      return {
        ...state,
        protcesSertification: action.payload,
        loading: false
      }
    default:
      return state;
  }
}

export const getDocReducer = (state = initialStateDoc, action) => {
  switch (action.type) {
    case GET_DOC:
      return {
        ...state,
        getDocs: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const costumersReducer = (state = initialStateCostumers, action) => {
  switch (action.type) {
    case GET_COSTUMERS:
      return {
        ...state,
        costumers: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const compassesContactReducer = (state = initialStateCompassesContact, action) => {
  switch (action.type) {
    case GET_COMPASSES_CONTACT:
      return {
        ...state,
        compassesContact: action.payload,
        loading: false
      }
    default:
      return state;
  }
}

export const getMostQuestionsReducer = (state = initialStateMostQuestions, action) => {
  switch (action.type) {
    case MOST_QUESTIONS:
      return {
        ...state,
        mostQuestions: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const getOrgReducer = (state = initialStateOrg, action) => {
  switch (action.type) {
    case GET_SINGLE_ORG:
      return {
        ...state,
        singleOrg: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
