import React, { useEffect, useState } from "react";
import CommonSection from "../../components/common-section/CommonSection";
import { getCompassesContact, sendContact } from "../../redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ConnectionSection from "../../components/connection";

export default function Connection() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filial, setFilial] = useState("Bosh ofis");

  const compassesContact = useSelector(
    (state) => state.compassesContact
  ).compassesContact;
  const { location } = useSelector((state) => state.location);

  useEffect(() => {
    dispatch(getCompassesContact());
  }, []);

  return (
    <>
      <CommonSection title={t("contact")} breadCrumb={t("contact")} />
      <div
        className="container mx-auto pt-24"
        data-aos={"fade-up"}
        data-aos-duration={"2000"}
      >
        <div className="flex justify-between mb-10">
          <h2 className="text-logo mb-8 text-4xl font-medium">
            {t("contact")}
          </h2>

          <div className="flex gap-6 items-center">
            <span className="text-xl">{location === "uz" ? "Filialni tanlang: " : "Выберите филиал: "} </span>
            <select
              value={filial}
              onChange={(e) => setFilial(e.target.value)}
              className="p-4 border border-bd rounded"
            >
              {compassesContact.slice(0).reverse().map((el) => (
                <option key={el.id}>{location === "uz" ? el.filial_uz : el.filial_ru}</option>
              ))}
            </select>
          </div>
        </div>

        {compassesContact.map((el) => (
          <div className="flex gap-10 flex-wrap">
            {(el.filial_uz == filial || el.filial_ru == filial) && (
              <>
                <div className="w-[65%] lg1:w-full overflow-hidden">
                  <iframe
                    src={location == "uz" ? el.map_url_uz : el.map_url_ru}
                    width="100%"
                    height="100%"
                    style={{ border: "0" }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="unicon"
                  />
                </div>
                <div className="w-[calc(35%-40px)] lg1:w-full bg-[#FCFCFD] p-6 border border-[#EAECF0]">
                  <div className="h-80 overflow-hidden mb-5">
                    <img className="w-full object-contain" src={el.image} alt="rasm" />
                  </div>

                  <div className="flex gap-3 c-col pb-4 border-b border-bd mb-5">
                    <dl className="flex flex-col gap-3">
                      <dt className="font-medium text-lg text-logo">
                        {t("personal_appeal")}
                      </dt>
                      <dd className="font-medium text-lg w-72">
                        {location === "uz" ? el.destination_uz : el.destination_ru}
                      </dd>
                    </dl>
                  </div>

                  <div className="flex gap-3 c-col pb-4 border-b border-bd mb-5">
                    <dl className="flex flex-col gap-3">
                      <dt className="font-medium text-lg text-logo">
                        {t("work-day")}
                      </dt>
                      <dd className="font-medium text-lg w-72">{location === "uz" ? el.worktime_uz : el.worktime_ru}</dd>
                    </dl>
                  </div>

                  <div className="flex gap-3 c-col pb-4 border-b border-bd mb-5">
                    <dl className="flex flex-col gap-3">
                      {/* <dt className="font-medium text-lg text-logo">
                        {t("email")}
                      </dt> */}
                      <dd className="font-medium text-lg w-72">{el.email}</dd>
                    </dl>
                  </div>

                  <div className="flex gap-3 c-col pb-4">
                    <dl className="flex flex-col gap-3">
                      {/* <dt className="font-medium text-lg text-logo">Tel</dt> */}
                      <dd className="font-medium text-lg w-72">{el.contact}</dd>
                    </dl>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}

        <ConnectionSection />
      </div>
    </>
  );
}
